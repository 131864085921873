//service part

.service-wrapper{
    overflow: hidden;
}

.service{
    background: #fff;
    box-shadow: 0 10px 25px rgba(0,0,0,0.05);
    transition: 0.3s ease-in-out;
    margin-top: 30px;

    &:hover{
        transform: translateY(-15px);
    }

    &__inner{
        min-height: 325px;
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img{
            margin-top: auto;
        }
    }
}

//circle

.circle{
    position: absolute;
    top: 20%;
    right: 15%;
    animation: circ 20s linear infinite;
}

.img-wrap{
    position: relative;
}

.circ{
    width: 15rem;
    height: 15rem;
    border: 3rem solid $secondary;
    animation: circ 20s linear infinite;
    position: absolute;
    border-radius: 50%;
    z-index: -1;

    &.circ--left{
        top: 15%;
        left: 20%;
    }

    &.circ--right{
        top: 10%;
        right: 20%;
    }

    &.circ--primary{
        border-color: $primary;
    }

    &.circ--grey{
        border-color: #F0F3F7;
    }

    &.circ--lg{
        width: 35rem;
        height: 35rem;
        border-width: 5rem;
    }
}

//loader

.loader{
    @include centered;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 15;
    background: #fff;

    &-item{
        display: inline-block;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border-left: 5px solid $primary;
        border-right: 5px solid $secondary;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        animation: spin 1s linear infinite;
    }
}

//counter

.counter{
    display: flex;
    flex-wrap: wrap;

    &__box{
        width: 100%;
        margin: 0;
        display: flex;

        @include media-breakpoint-up(sm) {
            width: 45%;
            margin: 2.5%;
        }
    }

    &__box-inner{
        width: 100%;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $secondary;

        &-num{
            font-weight: 800;
            font-size: 2.5rem;
            color: $tertiary;
            font-family: $secondaryFont;
            display: inline-block;
            margin-bottom: 5px;
        }

        p{
            color: $tertiary;
            font-weight: 500;
        }

        &.counter__box-inner--lg{
            height: 300px;
        }

        &.counter__box-inner--img{
            background: url(../img/office2.jpg)no-repeat center;
            background-size: cover;
        }

        &.counter__box-inner--tertiary{
            background: $primary;

            p,.counter__box-inner-num{
                color: #fff;
            }
        }

        &.counter__box-inner--sm{
            width: 100%;
            min-height: 200px;

            @include media-breakpoint-up(sm) {
                width: 70%;
            }
        }
    }
}

//contact-sec

.contact-sec{
    background: #161a48;

    h2{color: #fff;}
}