//top-nav

.top-nav{
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 4;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    transition: 0.3s ease-in-out;

    @include media-breakpoint-up(lg) {
        padding: 0 30px;
        top: 15px;
    }

    &.top-nav--onScroll{
        box-shadow: 0 2px 7px 0 rgba(0,0,0,.05);
        background: rgba(255,255,255,1);
        top: 0;

        .top-nav__menu ul li a, .top-nav__menu ul li span{
            color: $tertiary;
        }

        .top-nav__logo-img{

            content: url(../img/logo-blue.png);
        }
    }

    &__logo{
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &-img{
            max-height: 35px;
            transition: 0.3s ease-in-out;
        }

    }

    &__menu{
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }

        ul li{
            float: left;
            list-style: none;
            transition: 0.3s ease-in-out;
            opacity: .5;

            &:hover, &.active{
                opacity: 1;
            }
        }

        ul li a, ul li span{
            padding: 0 10px;
            font-family: $secondaryFont;
            font-size: 13px;
            display: block;
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 70px;
            cursor: pointer;
        }

    }

    &__btn{
        position: relative;
        width: 70px;
        height: 100%;
        top: 0;
        right: -15px;
        background: url(../img/menu.png) $secondary 50% no-repeat;
        display: block;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

}