//tabs

.tab{
    list-style: none;

    &__link-wrap{
        position: absolute;
        display: flex;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);
        top: -35px;

        @include media-breakpoint-up(sm) {
            top: -45px;
        }
    }

    &__link{
        width: 70px;
        height: 70px;
        margin: 0 20px;
        border-radius: 50%;
        border: 5px solid #fff;
        padding: 15px;;
        color: $primary;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: 800;
        font-size: 1.2rem;
        position: relative;
        text-align: center;
        transition: 0.3s ease-in-out;

        &:before{
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: $secondary;
            transform-origin: center;
            transform: scale(0);
            transition: 0.3s ease-in-out;
            z-index: -1;
        }

        &.active:before{
            transform: scale(1);
        }

        @include media-breakpoint-up(sm) {
            font-size: 1.7rem;
            width: 90px;
            height: 90px;
            border: 10px solid #fff;
        }
    }

    &__content{
        animation: fadeInDownSmall 0.5s linear;
        display: none;

        &.active{
            display: block;
        }
    }


}