//accordion

.accordion{

    &__tab{

        &+&{margin-top: 10px;}

        &.active{
            .accordion__tab-head{
                background: $secondary;
                color: #000;
                border-color: $secondary;
                &:before {transform: translateY(-50%) rotate(180deg);}
            }
            .accordion__tab-content{
                overflow: visible;

                &-inner{opacity: 1;}
            }
        }

        &-head{
            padding: 10px 20px;
            color: $tertiary;
            background: #F0F3F7;
            font-weight: 500;
            font-size: 0.8rem;
            text-transform: capitalize;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            position: relative;

            @include media-breakpoint-up(lg) {
                font-size: 0.9rem;
            }

            &:before{
                content: url('../img/chevron.png');
                position: absolute;
                right: 20px;
                top: 50%;
                height: 16px;
                transform-origin: center;
                transform: translateY(-50%);
            }

            &:hover{
                background: $secondary;
                color: $primary;
                padding-left: 25px;
            }
        }

        &-content{
            height: 0;
            overflow: hidden;
            transition: 0.3s ease;
            
            &-inner{
                transition: 0.3s 0.1s ease-in-out;
                opacity: 0;
                padding: 25px 20px;

                p{text-align: justify;}
            }
        }

        &-btn{
            color: #fff;
            padding: 5px 10px;
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 600;
            background: $tertiary;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover{
                color: $secondary;
                background: #fff;
            }
        }

    }
}