@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primaryFont: 'Montserrat', sans-serif;
$secondaryFont: 'Roboto', sans-serif;

//Bootstrap variables override

$primary: #181D50;
$secondary: #9DFFE4;
$tertiary: #282828;