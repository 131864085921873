//icons

.icon{
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &-close{
        background-image: url("../img/close.svg");
        width: 35px;
        height: 35px;
        cursor: pointer;
    }
}