.contact-form{
    position: relative;

    &__input, &__textarea{
        width: 100%;
        border: 1px solid #F0F3F7;
        border-radius: 5px;
        height: 40px;
        padding: 0 15px;
        background: #fff;
        color: #878a8f;
        outline: 0;
        font-size: 0.8rem;
        transition: 0.3s ease-in-out;

        &.error{
            border: 1px solid #E24C4B;
        }

        &:focus{
            padding-left: 20px;
            border-color: $secondary;
        }
    }

    &__textarea{
        min-height: 100px;
        max-height: 200px;
        padding-top: 20px;
    }

    &__input-wrap{
        position: relative;

        input[type="file"]{
            display: none;
        }

        .file-btn{
            padding: 10px 40px 10px 15px;
            cursor: pointer;
            color: #fff;
            font-size: 13px;
            display: inline-block;
            text-transform: uppercase;
            background: url('../img/upload.svg')$secondary no-repeat 92% center;
            transition: 0.3s ease-in-out;

            &:hover{
                background-color: $tertiary;
            }
        }
    }

    //placeholder for Icon before form txt

    %icon{
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    &__txt{
        display: block;
        margin-top: 10px;
        font-size: 13px;
        color: #000;

        &.contact-form__txt--error{
            color: #E24C4B;
            animation: headShake 0.5s linear;

            &:before {
                @extend %icon;
                content: url("../img/error.png");
            }
        }

        &.contact-form__txt--success{
            color: #4AD295;
            animation: fadeInUp 0.5s linear;

            &:before {
                @extend %icon;
                content: url("../img/check.png");
            }
        }
    }

}