//slider

.slider{
    height: 100vh;
    min-height: 650px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    background: url(../img/slide1.jpg);
    background-size: cover;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        background-attachment: fixed;
    }

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../img/overlay.png);
        background-size: cover;
        background-image: linear-gradient(200deg,rgba(33,33,33,.6) 5%,$primary 100%);
        z-index: -1;
    }

    &.slider--content{
        height: 30vh;
        min-height: 350px;

        .slider__text-m{
            font-size: 1.5rem;

            @include media-breakpoint-up(sm) {
                font-size: 2rem;
            }
        }
    }

    &__text{
        position: relative;
        padding: 50px 0 50px 15px;

        &-m{
            text-transform: uppercase;
            font-weight: 800;
            font-size: 2rem;
            color: #fff;
            line-height: 1.1;
            font-family: $primaryFont;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                font-size: 3.5rem;
            }
        
            @include media-breakpoint-up(lg) {
                font-size: 4.5rem;
            }
        }

        &-s{
            color: #fff;
            font-size: 0.9rem;
            opacity: 0.8;
            display: block;

            @include media-breakpoint-up(lg) {
                font-size: 1.4rem;
            }

            &:before{
                content: '';
                width: 100px;
                height: 10px;
                display: inline-block;
                background: $secondary;
                vertical-align: middle;
                margin-right: 20px;
            }
        }
    }

    .fix-circ{
        position: absolute;
        border: 5rem solid $secondary;
        border-radius: 50%;
        width: 30rem;
        height: 30rem;
        bottom: -30%;
        left: 5%;
        opacity: 0.5;
    }
}

//cta

.cta{
    background: #fff;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    padding: 40px;
    margin-top: 0;
    position: relative;
    z-index: 2;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
        width: 28%;
        margin-top: -200px;
    }

    &:before {
        content: '';
        border: 15px solid transparent;
        border-bottom-color: $primary;
        transform: rotate(-45deg);
        display: inline-block;
        position: absolute;
        top: -5px;
        left: -5px;
    }

    &__btn{
        cursor: pointer;
    }

    h2{
        font-size: 1.2rem;
        text-transform: none;
    }

    p{
        font-weight: 500;
        width: 80%;
        color: $tertiary;
        margin-top: 25px;
    }
}