.modal{
    position: fixed;
    background: rgba(255,255,255,.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30; 
    @include centered;
    animation: fadeIn linear 0.3s;
    display: none;

    &.modal--open{
        display: flex;
    }

    &__content{
        border-radius: 5px;
        background: #fff;
        width: 90%;
        max-width: 450px;
        overflow: hidden;
        animation: zoomIn linear 0.3s;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
        
        &-head{
            height: 5rem;
            background: url(../img/success.png) $primary no-repeat center center;
            background-size: 60px;
        }

        &-txt{
            padding: 30px;
            text-align: center;

            h3{
                font-size: 1.4rem;
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        &-close{
            display: inline-block;
            padding: 5px 20px;
            border-radius: 3px;
            background: $secondary;
            color: #000;
            font-size: 0.8rem;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: 0.3s ease-in-out;
            cursor: pointer;

            &:hover{
                background: #e4e4e4;
                color: $tertiary;
            }
        }

        &.modal__content--error{

            .modal__content-head{
                background: url(../img/error-ico.png) $primary no-repeat center center;
                background-size: 60px;
            }

        }
    }
}

.is-loading{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: rgba(255,255,255,.7);
    animation: fadeIn linear 0.3s;
    @include centered;

    &__spinner{
        display: inline-block;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border-left: 5px solid $primary;
        border-right: 5px solid $secondary;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        animation: spin 1s linear infinite;
    }

}