// scroll up button

.back-to-top{
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0,0,0,.15);
    background: url(../img/scroll-top.svg) $secondary no-repeat center;
    position: fixed;
    bottom: 80px;
    right: 15px;
    z-index: 3;
    cursor: pointer;

    &.active{
        display: block;
    }
}

//scroll-down

.scroll-down{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 10px;
    display: block;
    width: 60px;
    height: 60px;
    background: url(../img/arrow-down.svg)no-repeat center;
    background-size: 50%;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover{
        transform: translate(-50%, -15px);
    }
}

// primary-btn

.btn{
    border-radius: 0;
    padding: 15px 45px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    transition: .3s ease-in-out;
    color: #fff;
    outline: none!important;
    box-shadow: none!important;
    overflow: hidden;
    border: 0;

    &:before {
        content: '';
        border: 10px solid transparent;
        border-bottom-color: #fff;
        transform: rotate(135deg);
        display: inline-block;
        position: absolute;
        bottom: -5px;
        right: -5px;
        transition: .3s ease-in-out;
    }

    &-primary{
        background: $primary;

        &:hover{
            color: #000;
            background: $secondary;

            &:before{
                border-bottom-color: #000;
            }
        }
    }

    &-secondary{
        background: #fff;
        color: $primary;

        &:before{
            border-bottom-color: $primary;
        }

        &:hover{
            color: #000;
            background: $secondary;

            &:before{
                border-bottom-color: $primary;
            }
        }

    }

    &-tertiary{
        background: $secondary;
        color: #000;

        &:before{
            border-bottom-color: #000;
        }

        &:hover{
            background: $primary;
            color: #fff;

            &:before{
                border-bottom-color: #fff;
            }
        }
    }
}