
//overflow

.ov-h{overflow: hidden!important;}
.ov-v{overflow: visible!important;}

//text-transform

.txt-t-n{
    text-transform: none!important;
}

.bg-grey{
    background: #F0F3F7;
}